var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"mb-0",attrs:{"footer-tag":"footer"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-button-loading',{attrs:{"text":"CREAR CONTRATO","type":"submit","processing":_vm.processing}})]},proxy:true}])},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"title"}},[_c('strong',[_vm._v("TÍTULO DEL CONTRATO")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Título del Contrato"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":"Ej. Contrato de Prestación de Servicios","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"contract_number"}},[_c('strong',[_vm._v("NÚMERO DE CONTRATO")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Número de Contrato"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contract_number","placeholder":"Ingrese número de contrato","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.contract_number),callback:function ($$v) {_vm.$set(_vm.formData, "contract_number", $$v)},expression:"formData.contract_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"contractType"}},[_c('strong',[_vm._v("TIPO DE DOCUMENTO")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Tipo de Documento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"contractType","placeholder":"Seleccione","state":errors.length > 0 ? false : null,"options":_vm.options.contractTypes},model:{value:(_vm.formData.contract_type),callback:function ($$v) {_vm.$set(_vm.formData, "contract_type", $$v)},expression:"formData.contract_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"contractService"}},[_c('strong',[_vm._v("TIPO DE SERVICIO")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Tipo de Servicio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"contractService","placeholder":"Seleccione","state":errors.length > 0 ? false : null,"options":_vm.options.contractServices},model:{value:(_vm.formData.contract_service),callback:function ($$v) {_vm.$set(_vm.formData, "contract_service", $$v)},expression:"formData.contract_service"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"start_date"}},[_c('strong',[_vm._v("FECHA DE INICIO")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Fecha de Inicio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"start_date","state":errors.length > 0 ? false : null,"config":_vm.onlyDateConfig,"placeholder":"YYYY-MM-DD"},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.clearDate('start_date')}}},[_c('feather-icon',{attrs:{"icon":"RefreshCwIcon","size":"12"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"end_date"}},[_c('strong',[_vm._v("FECHA DE CULMINACIÓN")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Fecha de Culminación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"end_date","state":errors.length > 0 ? false : null,"config":_vm.onlyDateConfig,"placeholder":"YYYY-MM-DD"},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.clearDate('end_date')}}},[_c('feather-icon',{attrs:{"icon":"RefreshCwIcon","size":"12"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"provider"}},[_c('strong',[_vm._v("PROVEEDOR")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Proveedor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"provider","placeholder":"Seleccione","state":errors.length > 0 ? false : null,"options":_vm.options.providers},model:{value:(_vm.formData.provider),callback:function ($$v) {_vm.$set(_vm.formData, "provider", $$v)},expression:"formData.provider"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"department"}},[_c('strong',[_vm._v("DEPARTAMENTO RESPONSABLE")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Departamento Responsable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"department","placeholder":"Seleccione","state":errors.length > 0 ? false : null,"options":_vm.options.businessAreas},model:{value:(_vm.formData.department),callback:function ($$v) {_vm.$set(_vm.formData, "department", $$v)},expression:"formData.department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"description"}},[_c('strong',[_vm._v("DESCRIPCIÓN DEL CONTRATO")])]),_c('b-form-textarea',{staticClass:"char-textarea",attrs:{"id":"description","placeholder":"Describe brevemente el contenido y los objetivos del contrato","rows":"3"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"textarea-counter-value float-right"},[_c('strong',{staticClass:"char-count"},[_vm._v("Caracteres: "+_vm._s(_vm.formData.description ? _vm.formData.description.length : 0))])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }