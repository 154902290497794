<template>
	<validation-observer ref="refForm">
		<b-form
			@submit.prevent="onSubmit"
			@reset.prevent="resetForm"
		>
			<b-row>
				<b-col md="12">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<!-- CONTRACT TITLE -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="title">
									<strong>TÍTULO DEL CONTRATO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Título del Contrato"
								>
									<b-form-input
										id="title"
										v-model="formData.name"
										placeholder="Ej. Contrato de Prestación de Servicios"
										:state="errors.length > 0 ? false : null"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- CONTRACT NUMBER -->
							<b-col
								cols="12"
								md="3"
								class="mb-2"
							>
								<label for="contract_number">
									<strong>NÚMERO DE CONTRATO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Número de Contrato"
								>
									<b-form-input
										id="contract_number"
										v-model="formData.contract_number"
										placeholder="Ingrese número de contrato"
										:state="errors.length > 0 ? false : null"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- CONTRACT TYPE -->
							<b-col
								cols="12"
								md="3"
								class="mb-2"
							>
								<label for="contractType">
									<strong>TIPO DE DOCUMENTO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Tipo de Documento"
								>
									<v-select
										id="contractType"
										placeholder="Seleccione"
										v-model="formData.contract_type"
										:state="errors.length > 0 ? false : null"
										:options="options.contractTypes"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- CONTRACT SERVICE -->
							<b-col
								cols="12"
								md="6"
								class="mb-2"
							>
								<label for="contractService">
									<strong>TIPO DE SERVICIO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Tipo de Servicio"
								>
									<v-select
										id="contractService"
										placeholder="Seleccione"
										v-model="formData.contract_service"
										:state="errors.length > 0 ? false : null"
										:options="options.contractServices"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- START DATE -->
							<b-col
								cols="12"
								md="6"
								class="mb-2"
							>
								<label for="start_date">
									<strong>FECHA DE INICIO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Fecha de Inicio"
								>
									<b-input-group>
										<flat-pickr
											id="start_date"
											v-model="formData.start_date"
											:state="errors.length > 0 ? false : null"
											class="form-control"
											:config="onlyDateConfig"
											placeholder="YYYY-MM-DD"
										/>
										<b-input-group-append>
											<b-button
												variant="primary"
												@click="clearDate('start_date')"
											>
												<feather-icon
													icon="RefreshCwIcon"
													size="12"
												/>
											</b-button>
										</b-input-group-append>
									</b-input-group>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- END DATE -->
							<b-col
								cols="12"
								md="6"
								class="mb-2"
							>
								<label for="end_date">
									<strong>FECHA DE CULMINACIÓN</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Fecha de Culminación"
								>
									<b-input-group>
										<flat-pickr
											id="end_date"
											v-model="formData.end_date"
											:state="errors.length > 0 ? false : null"
											class="form-control"
											:config="onlyDateConfig"
											placeholder="YYYY-MM-DD"
										/>
										<b-input-group-append>
											<b-button
												variant="primary"
												@click="clearDate('end_date')"
											>
												<feather-icon
													icon="RefreshCwIcon"
													size="12"
												/>
											</b-button>
										</b-input-group-append>
									</b-input-group>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- PROVEEDOR -->
							<b-col
								cols="12"
								md="6"
								class="mb-2"
							>
								<label for="provider">
									<strong>PROVEEDOR</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Proveedor"
								>
									<v-select
										id="provider"
										placeholder="Seleccione"
										v-model="formData.provider"
										:state="errors.length > 0 ? false : null"
										:options="options.providers"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- DEPARTMENT -->
							<b-col
								cols="12"
								md="6"
								class="mb-2"
							>
								<label for="department">
									<strong>DEPARTAMENTO RESPONSABLE</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Departamento Responsable"
								>
									<v-select
										id="department"
										placeholder="Seleccione"
										v-model="formData.department"
										:state="errors.length > 0 ? false : null"
										:options="options.businessAreas"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- CONTRACT DESCRIPTION -->
							<b-col
								cols="12"
								class="mb-1"
							>
								<label for="description">
									<strong>DESCRIPCIÓN DEL CONTRATO</strong>
								</label>
								<b-form-textarea
									id="description"
									v-model="formData.description"
									class="char-textarea"
									placeholder="Describe brevemente el contenido y los objetivos del contrato"
									rows="3"
								/>
								<small class="textarea-counter-value float-right">
									<strong
										class="char-count"
									>Caracteres: {{ formData.description ? formData.description.length : 0 }}</strong>
								</small>
							</b-col>
						</b-row>

						<template #footer>
							<!-- BUTTON SUBMIT -->
							<b-button-loading
								text="CREAR CONTRATO"
								type="submit"
								:processing="processing"
							></b-button-loading>
						</template>
					</b-card>
				</b-col>
			</b-row>
		</b-form>
	</validation-observer>
</template>

<script>
import useNotifications from "@notifications"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import contractStoreModule from "../contractStoreModule"
import { Spanish } from "flatpickr/dist/l10n/es"
import flatPickr from "vue-flatpickr-component"
import { required } from "@validations"
import vSelect from "vue-select"
import router from "@/router"
import moment from "moment"
import store from "@/store"
import axios from "@axios"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BButtonLoading,

		flatPickr,
		required,
		vSelect
	},
	setup() {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const refForm = ref(null)
		const processing = ref(false)
		const options = ref({})

		const onlyDateConfig = ref({
			enableTime: false,
			locale: Spanish,
			/* minDate: moment().add(1, "days").format("YYYY-MM-DD") */
			minDate: moment().format("YYYY-MM-DD")
		})

		const blankData = {
			contract_number: null,
			name: null,
			description: null,
			start_date: null,
			end_date: null,
			contract_service: null,
			contract_type: null,
			provider: null,
			department: null
		}

		const formData = ref(JSON.parse(JSON.stringify(blankData)))
		const resetFormData = () => {
			formData.value = JSON.parse(JSON.stringify(blankData))
		}

		const CONTRACT_APP_STORE_MODULE_NAME = "app-contract"

		// REGISTER MODULE
		if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME))
			store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME))
				store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			getSelectFilters()
		})

		const getSelectFilters = async () => {
			try {
				const [services, types, providers, businessAreas] = await Promise.all([
					axios.get("/selectors/contract-services"),
					axios.get("/selectors/contract-types"),
					axios.get("/selectors/providers"),
					axios.get("/selectors/business-areas")
				])
				options.value = {
					...services.data,
					...types.data,
					...providers.data,
					...businessAreas.data
				}
			} catch (error) {
				toastNotification("Error al cargar datos de selección", false)
			}
		}

		const clearDate = (index) => {
			formData.value[index] = null
		}

		const onSubmit = async () => {
			const isValid = await refForm.value.validate()
			if (!isValid) {
				toastNotification("Complete los campos requeridos", false)
				return
			}

			processing.value = true

			const payload = {
				contract_number: formData.value.contract_number,
				name: formData.value.name,
				description: formData.value.description,
				start_date: formData.value.start_date,
				end_date: formData.value.end_date,
				contract_service_id: formData.value.contract_service?.value || null,
				contract_type_id: formData.value.contract_type?.value || null,
				provider_id: formData.value.provider?.value || null,
				department_id: formData.value.department?.value || null
			}

			store
				.dispatch("app-contract/createContract", payload)
				.then((response) => {
					toastNotification(response.data.message)

					resetFormData()
					refForm.value.reset()
					router.push({ name: "contracts-list" })
				})
				.catch((err) => {
					const message =
						err.response.data.message || "Error al agregar contrato"
					toastNotification(message, false)

					processing.value = false
				})
		}

		return {
			// DATA
			onlyDateConfig,
			processing,
			formData,
			options,

			// REFS
			refForm,

			// METHODS
			clearDate,
			onSubmit
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>